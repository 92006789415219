import React from 'react';

const InfoSection = () => {
  return (
<section className="p-6 rounded-lg shadow-md mb-8 font-hind-siliguri">
  <h2 className="text-white text-2xl font-semibold mb-4">Hallo liebe Besucher,</h2>
  <p className="text-white mb-4">
    Herzlich willkommen auf unserer Votingseite! Hier habt ihr die Möglichkeit, für euren Favoriten abzustimmen und ihn an die Spitze der Charts zu bringen.
  </p>
  <p className="text-white mb-4">
    <strong>Wie funktioniert das Voting?</strong><br />
    Unsere Promotionsendung läuft alle vier Wochen, jeweils am vierten Samstag des Monats von 17 bis 20 Uhr mit unserem Moderator Holger R. Ihr könnt bis Donnerstag, 18 Uhr, eure Stimme abgeben. Jeder von euch hat täglich die Chance, für bis zu fünf verschiedene Titel abzustimmen.
  </p>
  <p className="text-white mb-4">
    <strong>Wichtige Regeln:</strong><br />
    <ul>
      <li>Ein Titel pro Künstler: In unseren Votingcharts wird pro Künstler nur ein Titel berücksichtigt.</li>
      <li>Verweildauer: Jeder Titel bleibt maximal 8 Wochen in den Votingcharts. Wenn ein Künstler einen neuen Titel hat, kann er nach der Promotionsendung entscheiden, ob der alte oder der neue Titel in die Charts aufgenommen wird.</li>
      <li>Vorschau: Ihr könnt in jeden Titel reinhören oder das Video anschauen, indem ihr neben dem Titel auf "Vorhören" oder "YouTube" klickt.</li>
      <li>Fairness: Sollte festgestellt werden, dass versucht wird, das Voting durch den Einsatz von VPNs oder Proxys zu manipulieren, werden diese Stimmen nicht gezählt.</li>
    </ul>
  </p>
  <p className="text-white mb-4">
    <strong>Votingzeitraum:</strong><br />
    Das aktuelle Voting ist vom 24.08.2024 bis zum 26.09.2024 um 23:59 Uhr geöffnet.
  </p>
  <p className="text-white mb-4">
    Wir wünschen euch viel Spaß beim Abstimmen und drücken eurem Lieblingstitel die Daumen!
  </p>
</section>

  );
};

export default InfoSection;
