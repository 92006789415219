import React from 'react';
import Layout from '../components/Layout';
import ChartList from '../components/ChartList';
import InfoSection from '../components/InfoSection';

const ChartListPage = () => {
  return (
    <Layout>
      <InfoSection />
      <ChartList />
    </Layout>
  );
};

export default ChartListPage;
